<template>
  <div>
    <div class="mainbox">
      <div class="digitbox">
        <div class="err">4</div>
        <em class="far fa-question-circle fa-spin"></em>
        <div class="err">4</div>
      </div>
      <div class="msg">
        <div class="msgin">
          Maybe this page moved? Got deleted? Is hiding out in quarantine? Never
          existed in the first place?
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Error404",
  created() {
    let myLink = document.createElement("link");
    myLink.setAttribute(
      "href",
      "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600;900&display=swap"
    );
    myLink.setAttribute("rel", "stylesheet");
    document.head.appendChild(myLink);
    let myScript = document.createElement("script");
    myScript.setAttribute("src", "https://kit.fontawesome.com/4b9ba14b0f.js");
    myScript.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(myScript);
  },
};
</script>


<style scoped>
.mainbox {
  background-color: transparent;
  left: 0;
  bottom: 0;
  margin: auto;
  text-align: -webkit-center;
  display: table;
}

.digitbox {
  display: table-row;
}

.err {
  color: #ffffff;
  font-family: "Nunito Sans", sans-serif;
  font-size: 11rem;
  display: table-cell;
  width: 33%;
}

.far {
  font-size: 8.5rem;
  color: #ffffff;
  display: table-cell;
}

.msg {
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.6rem;
  display: table-caption;
  caption-side: bottom;
  padding: 10px;
}

.msgin {
  color: white;
  font-weight: bold;
}
</style>